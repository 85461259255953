/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const React = require("react");
const LayoutWrap = require("./src/layouts/wrap").default;

// Wraps every page in a component
exports.wrapPageElement = ({ element }) => {
  return (
    <LayoutWrap>
      {element}
    </LayoutWrap>
  );
};
