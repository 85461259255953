import React from "react";
import { Helmet } from "react-helmet";
import crypto from "crypto";
import { Toolkit_Url } from "@constants/common";
import icon from "@assets/images/newFavicon.png";

function base64URLEncode(str) {
  return str
    ?.toString("base64")
    ?.replace(/\+/g, "-")
    ?.replace(/\//g, "_")
    ?.replace(/=/g, "");
}

const Head = ({ description, lang, title, pathname }) => {
  const path = pathname.replace(/^\/|\/$/g, "");
  const siteTitle = title || "Meta Merge";
  const metaDescription =
    description ||
    "Capture your favorite pets, defeat enemies, mint NFTs, and embark on your adventure in the world of monsters in Meta Merge!";
  const language = lang || "en";
  const siteUrl = "https://metamerge.xyz";
  const bannerImage =
    "https://imagedelivery.net/8Bzk7TdcL7GACU5QQRsfaQ/7369dbbe-6795-4ce5-67f1-d9de8b887800/w=500";

  let pageUrl;
  pageUrl = `${siteUrl}/${path}`;
  pageUrl = pageUrl.replace(/^\/+/g, "");

  const domain = "metamerge.xyz";

  const nonce = base64URLEncode(crypto.randomBytes(32));
  const csp =
    `default-src 'self' ultiverse.io *.ultiverse.io *.googletagmanager.com imagedelivery.net static.cloudflareinsights.com *.sentry.io *.cloudflare.com *.cloudflareaccess.com *.particle.network *.unipass.id www.google-analytics.com; ` +
    `script-src 'self' 'strict-dynamic'${
      process.env.NODE_ENV === "development" ? " 'unsafe-eval'" : ""
    } 'nonce-${nonce}' *.googletagmanager.com *.cloudflare.com *.particle.network vercel.live; ` +
    `style-src 'self' 'unsafe-inline' *.googletagmanager.com fonts.googleapis.com *.cloudflare.com *.particle.network vercel.live; ` +
    `img-src 'self' 'unsafe-inline' imagedelivery.net ${Toolkit_Url}/loading.gif *.walletconnect.com *.particle.network *.googletagmanager.com ultiverse.io *.ultiverse.io data: blob:; ` +
    `script-src-elem 'self' 'nonce-${nonce}' *.googletagmanager.com *.cloudflare.com *.particle.network vercel.live static.cloudflareinsights.com;` +
    `font-src 'self' fonts.gstatic.com *.particle.network data:;` +
    `connect-src 'self' *.ankr.com *.cloudflare-eth.com *.googletagmanager.com *.ankr.com *.alchemy.com *.nodereal.io *.particle.network wss: https:`;

  return (
    <Helmet>
      {/* General tags */}
      <title>{siteTitle}</title>
      <link rel="icon" href={icon} />
      <meta name="description" content={metaDescription} />
      <meta name="image" content={bannerImage} />
      <meta
        name="robots"
        content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
      />
      {/* OpenGraph tags */}
      <meta property="og:locale" content={language} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={domain} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={bannerImage} />
      <meta property="og:image:secure_url" content={bannerImage} />
      <meta property="og:image:alt" content={siteTitle} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={bannerImage} />
      {/* Content-Security-Policy */}
      <meta property="csp-nonce" content={nonce} />
      <meta httpEquiv="Content-Security-Policy" content={csp} />

    </Helmet>
  );
};

Head.defaultProps = {
  lang: `en`,
  description: ``,
  pathname: "/",
};

export default Head;
