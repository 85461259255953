import React from "react";
import { Provider as AlertProvider } from "react-alert";
import { ConfigProvider, UserProvider } from "@ultiverse/frontend-toolkit";
import { AlertTemplate, alertOptions } from "@components/alert/alertTemplate";
import { ModalProvider } from "@components/modal/ModalProvider";
import Head from "@components/head";
import logoImg from "@data/images/loginLogo.png";
import { Merge_Chain_Id, Toolkit_Url } from "@constants/common";

import "@ultiverse/frontend-toolkit/dist/style.css";
import "../assets/css/global.css";

const Layout = ({ children }) => {
  const [projectId, clientKey, appId] =
    process.env.GATSBY_PARTICLE_CONFIG?.split(",") || new Array(3);

  return (
    <ConfigProvider
      options={{
        requestUrl: Toolkit_Url,
        apiKey: "bWV0YW1lcmdlQGRXeDBhWFpsY25ObA==",
        logo: logoImg,
        particle: {
          projectId,
          clientKey,
          appId,
          chainId: Number(Merge_Chain_Id),
        },
        providerMap: {
          bsc: "https://bsc-mainnet.nodereal.io/v1/59d628a906b44b66901138a9262917b9",
          bscTestnet:
            "https://bsc-testnet.nodereal.io/v1/621f98b87ee5467897e6bb595ee6eabd",
        },
      }}
    >
      <UserProvider>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <ModalProvider>
            <Head />
            {children}
          </ModalProvider>
        </AlertProvider>
      </UserProvider>
    </ConfigProvider>
  );
};

export default Layout;
