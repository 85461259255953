import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import { CloseIcon } from "@assets/Icons/common";
import * as styles from "./index.module.css";

const i18n = {
  confirm: "Confirm",
  cancel: "Cancel",
  view: "View",
};

const Modal = (props) => {
  const {
    title,
    close,
    confirm,
    view,
    confirmText,
    cancelText,
    viewText,
    className,
    children,
    footer,
  } = props;

  return (
    <div className={styles.modalWrap}>
      <div className={classNames(styles.modalContent, className)}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.desc}>{children}</div>
        <CloseIcon
          className={classNames(styles.close, "hover:opacity-70")}
          onClick={close}
        />
        {footer === null ? null : (
          <div className={styles.actionBottom}>
            {close && (
              <button
                className={classNames(styles.btn, "hover:opacity-80")}
                onClick={close}
              >
                {cancelText || i18n.cancel}
              </button>
            )}
            {confirm && (
              <button
                className={classNames(styles.btn, "hover:opacity-80")}
                onClick={confirm}
              >
                {confirmText || i18n.confirm}
              </button>
            )}
            {view && (
              <Link
                className={classNames(styles.btn, "hover:opacity-80")}
                to={view}
                onClick={close}
              >
                {viewText || i18n.view}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Modal;
