import React from "react";
import { positions } from "react-alert";
import { AlertIcon, SuccessIcon } from "@assets/Icons/common";
import * as styles from "./alertTemplate.module.css";

export const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <div className={styles.alertBox} style={style}>
      {/* {options.type === "info" && "!"} */}
      {options.type === "success" && (
        <span>
          <SuccessIcon />
        </span>
      )}
      {options.type === "error" && (
        <span>
          <AlertIcon /> Ooops!{" "}
        </span>
      )}
      <span className="pr-2">{message}</span>
      {/* <button onClick={close}>&times;</button> */}
    </div>
  );
};

export const alertOptions = {
  offset: "40px 10px 10px 10px",
  timeout: 5000,
  position: positions.TOP_CENTER,
  transition: "fade",
};
